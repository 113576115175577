<template>
  <div class="search">
    <el-select
      v-model="selectType"
      placeholder="请选择"
      class="select-style"
      v-if="isSelect"
      @change="select"
    >
      <el-option
        v-for="item in selectTypes"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-input
      v-model="searchText"
      placeholder="搜索作品"
      class="input-item"
      @keyup.enter.native="search"
    />
    <div class="search-icon">
      <img src="@/assets/images/search.png" @click="search" />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      searchText: "",
      selectType: "picture",
      selectTypes: [
        {
          value: "picture",
          label: "图片"
        },
        {
          value: "video",
          label: "视频"
        }
      ]
    };
  },
  props: {
    isSelect: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "picture"
    },
    text: {
      type: String,
      default: ""
    }
  },
  created() {
    this.selectType = this.type;
    this.searchText = this.text;
  },
  methods: {
    search() {
      this.$emit("change", { type: this.selectType, text: this.searchText });
    },
    select() {
      this.$emit("select", this.selectType);
    }
  },
  watch: {
    text() {
      this.searchText = this.text;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.search {
  width: 477px;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 25px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  .select-style {
    width: 90px;
    border-right: 1px solid #c0c0c0;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input__inner {
      background: transparent;
      border: none;
      font-size: 20px;
      font-family: PingFangSC-Regular;
      height: 50px;
      line-height: 50px;
      font-weight: 400;
      color: #fffefe;
    }
    .el-input__suffix {
      .el-select__caret {
        color: #dd7d8b;
      }
      .el-icon-arrow-up {
        &::before {
          content: "\e78f";
        }
      }
    }
  }
  .search-icon {
    width: 64px;
    display: flex;
    justify-content: center;
    img {
      width: 33px;
      height: intrinsic;
      cursor: pointer;
    }
  }
  .input-item {
    flex: auto;
    width: auto;
    .el-input__inner {
      background-color: transparent;
      padding: 0 20px;
      outline: none;
      border: none;
      border-right: 1px solid #c0c0c0;
      height: 36px;
      border-radius: 0;
      font-size: 20px;
      font-family: PingFangSC-Light;
      font-weight: 300;
      color: #ffffff;
      &::placeholder {
        color: #ffffff;
      }
      &::-webkit-input-placeholder {
        /* WebKit browsers 适配谷歌 */
        color: #ffffff;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 适配火狐 */
        color: #ffffff;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ 适配火狐 */
        color: #ffffff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+  适配ie*/
        color: #ffffff;
      }
    }
  }
}
.el-select-dropdown {
  .el-select-dropdown__wrap {
    .el-select-dropdown__item.selected {
      color: #dd7d8b;
    }
  }
}
</style>
