<template>
  <div class="home-page bg-dark">
    <el-carousel
      :interval="5000"
      :loop="true"
      arrow="never"
      indicator-position="none"
      height="100%"
      :autoplay="true"
    >
      <el-carousel-item v-for="item in indexList" :key="item.id">
        <el-image
          @contextmenu="handleMouse"
          :src="item.video_image"
          fit="cover"
          class="grid-img"
        >
        </el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="home-con">
      <div class="text">推开自然之门 昭示人文精华</div>
      <ul class="tags">
        <li
          v-for="item in searchtagsInfo"
          :key="item.id"
          :data-id="item.id"
          @click="handleSearch(item.tagname)"
        >
          {{ item.tagname }}
        </li>
      </ul>
      <search
        :isSelect="true"
        @change="searchVal"
        @select="selectType"
      ></search>
    </div>
    <div class="en">
      <span>CHINESE</span>
      <span>NATIONAL</span>
      GEOGRAPHY
    </div>
  </div>
</template>

<script>
import search from "@/components/search/index.vue";
export default {
  name: "index",
  components: { search },
  data() {
    return {
      autoplay: false,
      indexList: [],
      searchtagsInfo: [],
      searchType: "picture"
    };
  },
  async mounted() {
    const { data } = await this.$api.getindexList();
    if (data.code === 1) {
      console.log(data);
      this.indexList = data.data.indexlist;
      this.searchtagsInfo = data.data.searchtag_info;
    }
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    searchVal(val) {
      const { text, type } = val;
      console.log(text, type, "2222");
      if (type == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type, text }
        });
      }
      if (type == "video") {
        this.$router.push({
          name: "videoSearch",
          query: { type, text }
        });
      }
    },
    selectType(type) {
      this.searchType = type;
    },
    handleSearch(tagname) {
      if (this.searchType == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type: this.searchType, text: tagname }
        });
      }
      if (this.searchType == "video") {
        this.$router.push({
          name: "videoSearch",
          query: { type: this.searchType, text: tagname }
        });
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-page {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .el-carousel {
    width: 100%;
    height: 100%;
    .grid-img {
      width: 100%;
      height: 100%;
    }
  }
  .home-con {
    position: absolute;
    // top: 422px;
    top: 35%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    text-align: center;
    align-items: center;
    .text {
      font-size: 50px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      color: #ffffff;
    }
    .tags {
      display: flex;
      justify-content: center;
      margin: 28px 0 66px 0;
      li {
        font-size: 23px;
        font-family: PingFangSC-Thin;
        font-weight: 100;
        color: #ffffff;
        padding: 0 20px;
        border-right: 1px solid #ffffff;
        cursor: pointer;
        &:nth-child(1) {
          border-left: 1px solid #ffffff;
        }
      }
    }
  }
  .en {
    position: fixed;
    bottom: 51px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    font-size: 15px;
    z-index: 10;
    font-family: PingFangSC-Light;
    font-weight: 300;
    letter-spacing: 10px;
    color: #ffffff;
    span {
      padding-right: 30px;
    }
  }
}
</style>
